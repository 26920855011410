import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../constants/style';

const Title = styled.div`
  display: flex;
  margin-bottom: 1em;

  .text {
    margin: 0;
    color: ${COLORS.darkGold};
    font-weight: 900;
    max-width: 60%;
    ${props => (props.invert ? 'padding-left: .5em' : 'padding-right: .5em')};
    ${props => props.wing && 'padding: 0 1em; text-align: center;'}

    a {
      color: inherit;
      text-transform: none;
      text-decoration: none;
    }

    @media (max-width: 576px) {
      padding: 0;
      ${props => !props.wing && 'max-width: 90%;'}
    }
  }

  .line {
    border-top: 1px solid ${COLORS.darkGold};
    margin-top: 1em;
    flex-grow: 1;
    max-width: 50%;
    ${props => props.invert && 'order: -1'};
  }
`;

const LinedTitle = ({
  href = null,
  children,
  wing = false,
  invert = false,
  fontSize,
  lineStyle,
  textStyle,
  ...rest
}) => {
  return (
    <Title wing={wing} invert={invert} {...rest}>
      {wing && <div className="line" style={lineStyle} />}
      <h2 style={{ fontSize, ...textStyle }} className="text">
        <a href={href}>{children}</a>
      </h2>
      <div className="line" style={lineStyle} />
    </Title>
  );
};

export default LinedTitle;
