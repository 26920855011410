import React from 'react';
import styled from 'styled-components';
import LinedTitle from '../components/LinedTitle';
import { Container, CardImg, CardText, CardBody, Row, Col } from 'reactstrap';
import { COLORS } from '../constants/style';
import { Link } from 'gatsby';
import ReadMore from '../components/ReadMore';

const StudentSuccess = styled.section`
  padding-top: 80px;
  /* padding-bottom: 80px; */
  background: ${COLORS.lightGray};

  .card-link:hover {
    opacity: 0.95;
    .blog-title {
      color: ${COLORS.darkGold};
    }
    .readmore-text {
      font-weight: 900;
    }
  }

  .blog-card-img {
    height: 250px;
    width: 100%;
    object-fit: cover;
    filter: brightness(90%);
  }

  .blog-date {
    color: ${COLORS.darkGold};
    margin-bottom: 1em;
  }

  .blog-title {
    color: ${COLORS.textMain};
    font-weight: 900;
  }

  .blog-desc {
    color: ${COLORS.darkGray};
    font-size: 0.9em;
    p {
      margin: 0;
      margin-bottom: 0.5em;
    }
  }
`;

const SuccessStories = ({ posts, ...rest }) => {
  if (posts.length === 0) return null;

  return (
    <StudentSuccess id="student-success" {...rest}>
      <Container>
        <LinedTitle className="mb-0 text-center" fontSize="2.5em" wing>
          The Edcellent Insights
        </LinedTitle>
        <h5 className="text-center mb-5">
          Learning English empowers the mind and enriches life.
        </h5>
        <h4 className="text-center mb-4 font-weight-bold text-uppercase letter-spaced-1">
          <Link className="styless-link" to="/classes#enrol">
            Success Stories
          </Link>
        </h4>
        <Row>
          {posts.map(
            ({
              node: { id, title, shortDescription, featuredImage, slug, meta },
            }) => (
              <Col key={id} md="6" lg="4" className="px-3 pb-4 d-flex ">
                <Link
                  to={`/student-success/${slug}`}
                  className="card-link card border-0 rounded-0 text-decoration-none"
                >
                  <CardImg
                    {...featuredImage.sizes}
                    top
                    alt={`${title} featured image`}
                    className="blog-card-img rounded-0"
                  />
                  <CardBody className="flex-fill pt-4">
                    <h5 className="blog-title">Word of Advice from {title}</h5>
                    <CardText
                      className="blog-desc"
                      dangerouslySetInnerHTML={{ __html: shortDescription }}
                    />
                    <ReadMore url={`/student-success/${slug}`} />
                  </CardBody>
                </Link>
              </Col>
            )
          )}
        </Row>
        <hr className="m-0" />
      </Container>
    </StudentSuccess>
  );
};

export default SuccessStories;
