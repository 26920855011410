import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { COLORS } from '../constants/style';

const StyledLink = styled(Link)`
  border: solid 1px ${COLORS.brightGold};
  padding: 0.4em 0.7em;
  display: inline-block;
  margin-left: auto;
  text-align: right;
  color: ${COLORS.darkGold};
  font-size: 0.8em;

  &:hover {
    color: ${COLORS.textWhite};
    background: ${COLORS.brightGold};
    text-decoration: none;
  }
`;

const ReadMore = ({ url, text = 'READ MORE', ...rest }) => {
  return (
    <div {...rest}>
      <StyledLink to={url}>{text}</StyledLink>
    </div>
  );
};

export default ReadMore;
