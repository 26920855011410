import React, { useState } from 'react';
import styled from 'styled-components';

import { Container, Row, Col } from 'reactstrap';
import { COLORS } from '../constants/style';
import ModalVideo from 'react-modal-video';

const VideoSection = styled.section`
  padding-top: 80px;
  /* padding-bottom: 80px; */
  background: ${COLORS.lightGray};
`;

const FakePlayer = styled.div`
  height: 220px;
  width: 100%;
  background-image: url(${props => props.bgUrl});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;

  svg {
    height: 40px;
    position: absolute;
    top: 10px;
    right: 20px;

    fill: ${COLORS.brightGold};
  }

  &:hover {
    filter: brightness(80%);
    svg {
      fill: ${COLORS.lightGray};
    }
  }
`;

const InsightVideos = ({ posts, ...rest }) => {
  if (posts.length === 0) return null;

  const [vidModal, setVidModal] = useState('');

  const closeModal = () => setVidModal('');

  return (
    <VideoSection id="insight-videos" {...rest}>
      <ModalVideo
        channel="youtube"
        isOpen={vidModal.length}
        videoId={vidModal}
        onClose={closeModal}
        color="red"
        h1="asdfasdf"
      />
      <Container>
        <h4 className="text-center mb-4 font-weight-bold text-uppercase letter-spaced-1">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UCiKZjQwOqL0YD0hyW9bDr5w"
            className="styless-link"
          >
            Edcellent Videos
          </a>
        </h4>
        <Row className="mb-4">
          {posts.map(({ node: { youtubeVideoId, coverPhoto } }) => (
            <Col key={youtubeVideoId} md={6} lg={4}>
              <FakePlayer
                bgUrl={coverPhoto.sizes.src}
                onClick={() => {
                  setVidModal(youtubeVideoId);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                  <path d="M43.2,33.9c-0.4,2.1-2.1,3.7-4.2,4c-3.3,0.5-8.8,1.1-15,1.1c-6.1,0-11.6-0.6-15-1.1c-2.1-0.3-3.8-1.9-4.2-4C4.4,31.6,4,28.2,4,24c0-4.2,0.4-7.6,0.8-9.9c0.4-2.1,2.1-3.7,4.2-4C12.3,9.6,17.8,9,24,9c6.2,0,11.6,0.6,15,1.1c2.1,0.3,3.8,1.9,4.2,4c0.4,2.3,0.9,5.7,0.9,9.9C44,28.2,43.6,31.6,43.2,33.9z" />
                  <path fill="#3d3b39" d="M20 31L20 17 32 24z" />
                </svg>
              </FakePlayer>
            </Col>
          ))}
        </Row>
        <hr className="m-0" />
      </Container>
    </VideoSection>
  );
};

export default InsightVideos;
